@import "../../utils/includes.scss";

.widget-sr {
    &.groups__497{
        .has-secondary-background{
            background-color: #ffffff;
        }
        .widget-login>.widget-login-desktop{
            padding: 0;
        }
        .widget-login-popupElement .form-login button{
            text-align: center;
        }
        .paymentCalculator__paymentsInfos{
            .paymentCalculator__paymentsWrapper{
                .paymentCalculator__payment,
                .paymentCalculator__interests{
                    sup{
                        font-weight: $boldFontWeight;
                        vertical-align: super;
                        top: 3px;
                        left: 1px;
                    }
                }
            }
        }
        .listing-used-payment-wrap-range .target-payment .InputNumber.light-theme{
            input{
                border-radius: 0;
            }
            button{
                box-shadow: none;
            }
        }
        .listing-used-payment-wrap-range .trade-in__input .half{
            height: 50px;
        }
        .showroom-vdp-used-policywarranty img{
            display: none;
        }
        .vehicle-item .vehicle-info,
        .listing-new-tile .listing-new-tile-wrapper,
        .listing-tile,
        .listing-tile:hover{
            box-shadow: none;
            border: 1px solid #ddd;
        }
        form input[type=checkbox]+label:before,
        form input[type=checkbox]:checked+label:before{
            top: 6px;

        }
        form input[type=checkbox]+label:after,
        form input[type=checkbox]:checked+label:after{
            height: 8px;
            width: 8px;
            border: none;
            left: 2px;
            top: 5px;
        }
        .VehicleDisclaimersContent p{
            text-align: left;
        }
        .section-summary__content .car-images .demo-tag{
            top: 0;
            left: 0;
        }
    }
}

@include breakpoint(mobile){
    .widget-sr {
      &.groups__497 {
        .LoginBanner .LoginBannerWrapper {
          height: 34px;
          max-height: 34px;
          .login-content {
            display: flex;
            padding-right: 0;
            #button-login,
            #button-register {
              font-size: 13px !important;
              .label{
                font-size: 13px !important;
              }
            }
  
            #button-login.btn-login {
              margin-left: 5px;
            }
  
            #button-register.btn-register {
              margin-left: 10px;
            }
          }
        }
        .confirm_financing--wrapper{
          flex-direction: column;
        }
        .confirm_financing--title{
            font-size: 18px;
        }
        .TransactionAction--single>button{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
      }
    }
  }
@include breakpoint(mobileTablet) {
    .widget-sr {
        &.groups__497{
            .TransactionActionDetails--wrapper button,
            .TransactionActionDetails--wrapper .sr-button-outline-1{
                width: 100%;
            }
        }
    }
}
@include breakpoint(laptop) {
    .widget-sr {
        &.groups__497{
            .vdp-used-banner--image{
                max-height: 626px;
            }
        }
    }
}
@include breakpoint(desktop) {
    .widget-sr {
        &.groups__497{
            .vdp-used-banner--image{
                max-height: 681px;
            }
            .TransactionActionDetails--wrapper .earnings_listing{
                margin-bottom: 20px;
            }
            .TransactionActionDetails--wrapper button{
                margin: 10px 10px 0 0;
            }
            .TransactionActionDetails--wrapper button+button{
                margin: 0;
            }
        }
    }
}